import type { Integrations } from '~/types';

export function useIntegrationInstalled(integrationName: string) {
    const { user } = storeToRefs(userSessionStore());
    const { data: integrations } = useLazyFetch(`/api/users/${user.value?.id}/integrations`, {
        key: `integrations-user-${user.value?.id}`,
        headers: useRequestHeaders(['cookie']),
    });
    const isIntegrationInstalled = computed(() =>
        integrations.value?.some((integration: Integrations) => integration.integration.name == integrationName)
    );
    return isIntegrationInstalled.value;
}
